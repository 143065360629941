import React from 'react'
import TextField from '@material-ui/core/TextField'
import { validateField } from '../../utils/reactFormValidation'
import styled from 'styled-components'
import { withTranslation } from 'gatsby-plugin-react-i18next'
const StyledTextField = styled(TextField)`
    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px #fafafa inset;
    }
    && {
        input {
            &:-webkit-autofill {
                -webkit-box-shadow: 0 0 0 30px #fafafa inset;
            }
        }
    }
    p {
        font-size: 1rem;
    }
`

class CustomTextField extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: props.value,
            valid: typeof props.valid === undefined ? true : props.valid,
        }
    }

    onChange = (evt) => {
        const { name } = evt.target
        let { value } = evt.target
        const { regex, optional } = this.props
        if (this.props.maskingFn) {
            value = this.props.maskingFn(value)
        }
        const valid = validateField(value, regex, optional)

        this.props.onChange(name, value, valid)
        this.setState({
            valid,
            value,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.valid !== this.props.valid &&
            prevProps.valid !== this.props.valid
        ) {
            this.setState({
                valid: this.props.valid,
            })
        }
    }

    render() {
        const {
            id,
            name,
            label,
            helperText,
            multiline,
            rows,
            fullWidth,
            select,
            type,
            autoComplete,
            style,
            t,
        } = this.props
        const { valid, value } = this.state
        return (
            <StyledTextField
                select={select}
                fullWidth={fullWidth}
                id={id}
                name={name}
                label={t(label)}
                helperText={valid === false ? t(helperText) : null}
                type={type}
                multiline={multiline}
                rows={rows}
                value={value || ''}
                error={valid === false}
                onChange={this.onChange}
                variant="outlined"
                autoComplete={autoComplete}
                style={style}
            >
                {this.props.children}
            </StyledTextField>
        )
    }
}

export default withTranslation()(CustomTextField)
